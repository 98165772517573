import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { Component } from 'react';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { compose } from 'recompose';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { withNavigator } from 'src/hoc/index';
import { withSiteContext } from 'src/hoc/withSiteContext';
import { melioMeLocations } from 'src/pages/meliome/locations';
import { GlobalState } from 'src/redux/types';
import { getIsLoggedIn, getOwnedVendorId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import guestApi from 'src/services/api/guests';
import { Site } from 'src/sites/site';
import { getDefaultMemo } from 'src/utils/bills';
import { convertCurrencyToNumber } from 'src/utils/currency-utils';
import locations from 'src/utils/locations';
import { NavigateType } from 'src/utils/types';
import GuestRequestInfoPage from './components/GuestRequestInfoPage';
import { GuestDataProps, withGuestData } from './hoc/withGuestData';

type MapStateToProps = {
  ownedVendorId?: number | null;
  isLoggedIn: boolean;
};

type Props = GuestDataProps &
  MapStateToProps & {
    showMelioMeCard: boolean;
    navigate: NavigateType;
    editDisabled: boolean;
    site: Site;
  };

type State = {
  validationErrors: Record<string, any>;
  isLoadingInvoiceStatus: boolean;
};

const eventPage = 'payor';
const eventName = 'add-request-info';

class GuestRequestInfoPageContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      validationErrors: {},
      isLoadingInvoiceStatus: false,
    };
  }
  componentDidMount(): void {
    this.loadInvoiceStatus();
  }
  componentDidUpdate() {
    const { redirectUrl } = this.props;

    if (redirectUrl) {
      window.location.replace(redirectUrl);
    }
  }
  async loadInvoiceStatus() {
    const { link, totalAmount, invoiceNumber, navigate } = this.props;

    if (!isEmpty(totalAmount) && !isEmpty(invoiceNumber)) {
      this.setState({ isLoadingInvoiceStatus: true });
      try {
        const status = await guestApi.getInvoiceStatus({
          handle: link,
          invoiceNumber,
          totalAmount,
        });

        if (status?.link) {
          const url = generatePath(melioMeLocations.wizard.requestPayment, {
            link,
            hash: status.link,
          });
          navigate(url, false, {}, true);
        }
      } catch (e) {
        analytics.track(eventPage, 'error-invoice-status');
      } finally {
        this.setState({ isLoadingInvoiceStatus: false });
      }
    }
  }

  onNext = () => {
    const {
      invoiceNumber,
      totalAmount,
      navigateToGuestPage,
      vendorId,
      ownedVendorId,
      memo,
      site,
      nextRoute,
      intuitAcctNum,
    } = this.props;
    const convertedTotalAmount = convertCurrencyToNumber(totalAmount);
    const validationErrors = getValidationErrors('guest', { invoiceNumber, totalAmount: convertedTotalAmount }, [
      'invoiceNumber',
      'totalAmount',
    ]);
    const isNextDisabled = vendorId === ownedVendorId;

    if (isNextDisabled) {
      this.setState({
        validationErrors: {
          totalAmount: 'inputErrors.guest.totalAmount.custom.nopay',
        },
      });
    } else {
      this.setState({ validationErrors }, () => {
        if (isValidationOk(validationErrors)) {
          analytics.track(eventPage, `${eventName}-continue-success`);
          const data: any = {};

          if (isNil(memo)) {
            data.memo = getDefaultMemo(invoiceNumber, intuitAcctNum);
          }

          navigateToGuestPage(nextRoute, data, !site.embeddedMode);
        } else {
          analytics.track(eventPage, `${eventName}-validation-error`, validationErrors);
        }
      });
    }
  };

  goDashboard = () => this.props.navigate(locations.MainApp.dashboard.url());

  render() {
    const {
      logoUrl,
      companyName,
      totalAmount,
      invoiceNumber,
      invoiceFile,
      onChange,
      vendorId,
      ownedVendorId,
      showMelioMeCard,
      isLoading,
      blockCCMoneyIn,
      blockDebitCardMoneyIn,
      editDisabled,
      promotionName,
      isVendorAbsorbedFee,
      redirectUrl,
      onInvoiceFileChange,
      onInvoiceFileClear,
    } = this.props;

    const { validationErrors, isLoadingInvoiceStatus } = this.state;

    if (redirectUrl) {
      return null;
    }

    if (isLoading || isLoadingInvoiceStatus) {
      return <AreaLoader />;
    }

    return (
      <GuestRequestInfoPage
        logoUrl={logoUrl}
        vendorId={vendorId}
        companyName={companyName}
        totalAmount={totalAmount}
        invoiceNumber={invoiceNumber}
        validationErrors={validationErrors}
        onNext={this.onNext}
        isNextDisabled={vendorId === ownedVendorId}
        onChange={onChange}
        invoiceFile={invoiceFile}
        onInvoiceFileChange={onInvoiceFileChange}
        onInvoiceFileClear={onInvoiceFileClear}
        goDashboard={this.goDashboard}
        showMelioMeCard={showMelioMeCard}
        editDisabled={editDisabled}
        blockCCMoneyIn={blockCCMoneyIn}
        blockDebitCardMoneyIn={blockDebitCardMoneyIn}
        promotionName={promotionName}
        isVendorAbsorbedFee={isVendorAbsorbedFee}
      />
    );
  }
}

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  ownedVendorId: getOwnedVendorId(state),
  isLoggedIn: getIsLoggedIn(state),
});

export default compose(
  withNavigator(),
  withGuestData(),
  withSiteContext(),
  connect(mapStateToProps)
)(GuestRequestInfoPageContainer);
